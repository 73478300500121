<div class="search-results">
  <p class="search-results__result-col-title">
    {{ hits.label }}

    @if (isHistory) {
      <span>
        (<button
          type="button"
          class="search-results__clear-history qs-btn qs-btn--text-only qs-btn--small"
          (click)="clearHistory()">

          Clear
        </button>)
      </span>
    }
  </p>

  <ul class="search-results__result-list">
    @for (hit of hits.hits; track hit.id) {
      <li
        class="search-results__result-list-item"
        [ngClass]="{
          'search-results__result-list-item--content': hits.label === searchCategory.CONTENT,
          'search-results__result-list-item--topics': hits.label === searchCategory.TOPICS,
          'search-results__result-list-item--industries': hits.label === searchCategory.INDUSTRIES,
          'search-results__result-list-item--blogs': hits.label === searchCategory.BLOGS,
          'search-results__result-list-item--search': hits.label === searchCategory.RECENT_SEARCHES
        }">

        <a
          class="search-results__result-list-link"
          (click)="goToItem(hit.categoryType || hits.label, hit, hit?.categoryType === undefined)">

          {{ hit.name || hit.title }}

          @if (hit?.format?.name) {
            <span class="search-results__result-list-format">
              ({{ hit.format.name }})
            </span>
          }
        </a>
      </li>
    }
  </ul>
</div>
