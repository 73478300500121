import { Component, Input, inject } from '@angular/core';
import { ESearchCategory, ICombinedResults, IHit, THistoryHit } from '../../search-bar/search-bar.interface';
import { Router } from '@angular/router';
import { SearchModalService } from '../search-modal.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class SearchResultsComponent {
  protected readonly _router: Router = inject(Router);
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);

  @Input({ required: true }) hits!: ICombinedResults;
  @Input() isHistory: boolean = false;

  public readonly searchCategory: typeof ESearchCategory = ESearchCategory;

  private _getFormatSlug(productType: string): string {
    return productType.toLowerCase().replace(/\s/g, '-');
  }

  public goToItem(label: ESearchCategory, hit: IHit | THistoryHit, storeInHistory: boolean): void {
    let root;
    let slug;
    let fragment;

    switch (label) {
      case ESearchCategory.CONTENT:
        slug = hit.slug;
        root = 'content/product';

        if (hit.contents?.length) {
          slug = hit.contents[0].slug;
          fragment = this._getFormatSlug(hit.format.productType);
        }
        break;

      case ESearchCategory.INDUSTRIES:
        slug = hit.slug;

        if (hit.type === 'industry') {
          root = 'content/industry';
        }

        if (hit.type === 'military') {
          root = 'content/military';
        }
        break;

      case ESearchCategory.BLOGS:
        slug = hit.slug;
        root = 'quick-blog-infographics-trends';
        break;
    }

    if (storeInHistory) {
      this._searchModalService.addToSearchHistory(label, hit);
    }

    this._router.navigate([root, slug], { fragment });
    this._searchModalService.toggleSearchModal(false);
  }

  public clearHistory(): void {
    this._searchModalService.clearSearchHistory();
  }
}
